/* Container with column layout */
.tags-container {
    display: flex;
    flex-direction: column;
    padding: 8px;
    border: 1px solid #CBD5E0;
    /* Chakra's gray.300 color */
    border-radius: 8px;
    background-color: #EDF2F7;
    /* Chakra's gray.100 color */
    max-width: 400px;
    /* Optional: adjust based on your design */
}

.tags-row {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

/* Styling for the input field on top */
.tag-input {
    border: 1px solid #CBD5E0;
    width: 100%;
    border-radius: 15px;
    padding: 3% 4%;
    font-size: 14px;
    outline: none;
    background-color: #FFFFFF;
    color: #2D3748;
    /* Chakra's gray.800 color */
}

.ReactTags__selected {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

/* Individual tag styling */
.tag {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #422AFB;
    color: #FFFFFF;
    border-radius: 4px;
    padding: 2%;
    font-size: 14px;
    gap: 10px;
}

.tag-label {
    margin-right: 4px;
}

.tag-close-button {
    cursor: pointer;
    font-size: 12px;
    margin-left: 4px;
}